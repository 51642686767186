<template>
  <div>
    <h3>
      Confirm {{ itemType }} details
      <span v-if="currentItem?.sub_registration">
        <span v-if="currentItem.sub_registration?.local_jurisdiction">
          for {{ currentItem.sub_registration.local_jurisdiction.name }}
        </span>
      </span>
    </h3>
    <item-requiring-attention
      v-if="loaded"
      :item="currentItem"
      @completed="completed"
    />
    <ct-centered-spinner v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from "@/components/shared/CtCenteredSpinner"
import ItemRequiringAttention from "@/components/ItemRequiringAttention"
import { isRegisteredAgent, isFilingItem } from "../SchemaForm/helper"

export default {
  name: 'PostPaymentItemsRequiringAttention',

  components: {
    CtCenteredSpinner,
    ItemRequiringAttention,
  },

  props: {
    companyId: {
      type: String,
    },
    invoiceId: {
      type: String,
    },
  },
  data() {
    return {
      loaded: false,
      index: 0,
    }
  },

  computed: {
    ...mapGetters('invoices', ['find']),
    ...mapGetters('stageline', ['isStagelineRoute']),
    currentItem() {
      if (this.items.length > 0) {
        return this.items[this.index]
      }
      return null
    },
    itemType() {
      if (!this.loaded) {
        return ''
      } else if (isFilingItem(this.currentItem)) {
        return `${this.currentItem.name} `
      } else if (isRegisteredAgent(this.currentItem)) {
        return `${this.currentItem.product.description} `
      }
      return ''
    },
    invoice() {
      return this.find(this.invoiceId)
    },
    servicesRequiringAttention() {
      let services = []
      if (this.invoice?.services) {
        services = this.invoice.services
      }
      if (this.invoice?.subscription && this.invoice.subscription?.services) {
        services = [...services, ...this.invoice.subscription.services]
      }
      const uniqueServices = this.uniqueItems(services)
      return uniqueServices.filter(s => s.status === 'awaiting-client-input')
    },
    orderItemsRequiringAttention() {
      let orderItems = []
      if (this.invoice?.order) {
        orderItems = this.invoice.order.order_items
      }
      if (this.invoice?.subscription && this.invoice.subscription?.order_items) {
        orderItems = [...orderItems, ...this.invoice.subscription.order_items]
      }
      const uniqueOrderItems = this.uniqueItems(orderItems)
      return uniqueOrderItems.filter(oi => oi.status === 'awaiting-client-input')
    },
    items() {
      return [...this.servicesRequiringAttention, ...this.orderItemsRequiringAttention]
    },
    orderHasFilingItem() {
      return this.invoice.order.order_items.some(oi => {
        return oi.type === 'filing'
      })
    },
  },

  async mounted() {
    this.loaded = false
    await this.loadById({
      id: this.invoiceId,
      params: { include_details: true },
    })

    if (this.items.length === 0) {
      await this.completed()
    }
    this.loaded = true
  },

  methods: {
    ...mapActions('invoices', ['loadById']),
    async completed() {
      this.loaded = false
      this.index++
      if (this.index >= this.items.length) {
        if (this.isStagelineRoute) {
          this.$root.$emit('cart-next-page')
        } else {
          // await this.$router.push({ name: 'review', params: { invoiceId: this.invoiceId } })
          if (!this.orderHasFilingItem) {
            await this.$router.push({ name: 'order-confirmation', params: this.$router.currentRoute.params })
          } else {
            await this.$router.push({ name: 'pendingFilings' })
          }
        }
        this.loaded = true
        this.$emit('completed')
      } else {
        this.$nextTick(() => {
          this.loaded = true
        })
      }
    },
    uniqueItems(items) {
      return Array.from(new Set(items.map(i => i.id)))
        .map(id => items.find(i => i.id === id))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
