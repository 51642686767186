var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [
        _vm._v("\n    Confirm " + _vm._s(_vm.itemType) + " details\n    "),
        _vm.currentItem?.sub_registration
          ? _c("span", [
              _vm.currentItem.sub_registration?.local_jurisdiction
                ? _c("span", [
                    _vm._v(
                      "\n        for " +
                        _vm._s(
                          _vm.currentItem.sub_registration.local_jurisdiction
                            .name
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm.loaded
        ? _c("item-requiring-attention", {
            attrs: { item: _vm.currentItem },
            on: { completed: _vm.completed },
          })
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }